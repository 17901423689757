import React, { FC } from 'react';
import CacheService from '../service/CacheService';
import SessionService from '../service/SessionService';
import DataService from '../service/DataService';

interface IProps {
    botId: string | number;
    acctId: string | number;
    apiPaths: [string] | [string, string];
    children: any;
}

const GlobalServices = (props: IProps) => {
    return (
        <>
            <DataService>
                <SessionService>
                    <CacheService
                        apiPath={props.apiPaths[0]}
                        botId={props.botId}
                        acctId={props.acctId}
                    >
                        {/* <EventHandlingService> */}
                        {props.children}
                        {/* </EventHandlingService> */}
                    </CacheService>
                </SessionService>
            </DataService>
        </>
    );
};

export default GlobalServices;

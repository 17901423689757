import React from 'react';
import PropTypes from 'prop-types';
import WaveLoader from './WaveLoader';
import BounceLoader from './BounceLoader';

class Loaders {
    customizationColor: string = "bg-red-500";
    customizationType: string;

    constructor (customizationColor:string = 'bg-red-500', customizationType:string) {
        this.customizationColor = customizationColor
        this.customizationType = customizationType
    }

    getLoader = ():JSX.Element => {
        switch(this.customizationType) {
            case "wave":
                return <WaveLoader customizationColor={this.customizationColor} customizationType={this.customizationType} isCustomizationColor={true} />
            default:
                return <BounceLoader customizationColor={this.customizationColor} customizationType={this.customizationType} isCustomizationColor={true} />
        }
    };
}

export default Loaders;

import React from 'react';
import PropTypes from 'prop-types';

const rand = () => {
    return Math.random().toString(36).substring(2,15);
};

const token = () => {
    return rand() + rand();
};


export const generateToken = () => {
    return token();
};

export const generateChatMode = (percentage: number , queue:string, queueToAgent:boolean) => {

    let currentTime = new Date();
    let startTimeCA = new Date();
    let endTimeCA = new Date();
    startTimeCA.setHours(10,0,0);
    endTimeCA.setHours(19,0,0);

    console.log("CHECKING CA queue routing", queue, startTimeCA, currentTime, endTimeCA );
    console.log("CHECKING queue to agent straight", queueToAgent);

    if ( queue == "CA" && startTimeCA < currentTime && currentTime < endTimeCA) {

        return "agent"

    } 
    else if (queueToAgent) {
        return "agent"
        
    }else {

        if (percentage === 0) {
            return "agent"
        } else {
            if (Math.floor(Math.random()*100)> percentage) {
                return "agent"
            } else {
                return "bot"
            }
        }

    }

    // if (percentage === 0) {
    //     return "agent"
    // } else {
    //     if (Math.floor(Math.random())> percentage) {
    //         return "agent"
    //     } else {
    //         return "bot"
    //     }
    // }
}

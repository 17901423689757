import {
    createSlice,
    createAsyncThunk,
    Action,
    PayloadAction,
} from '@reduxjs/toolkit';
import { setState, getState } from '../../utils/localStorage';

interface argProps {
    name: string;
    value: any;
}

const initialState = {
    greetingMessage: 'Hello, I am Mario Bot! Nice to meet you :)!',
    chatRatingMessage: '',
    quickReplyMessage: 'Need Help? Click here!',
    noAnswer: "I'm sorry, I don't get what you mean. Try explain more to me :)",
    cancelForm: 'Visitor cancelled form.',
    uploadFileButton: 'Selece file',
    chooseIntents: 'Please choose from intents below,',
    networkProblem: 'Network disconected. Please restart.',
    noResponse:
        'We do not receive any respond from you. The chat session will be terminated.',
    inactiveWarning: 'You have been inactive for long time.',
    forceClose: 'You have another chat ongoing, this chat is terminated.',
    rejoinChat: 'Start Chat',
    reconnectChat: 'Reconnect',
    endChat: 'Chat session ended.',
    leaveChat: 'Visitor has left the chat.',
    endChatHeader: 'Confirm to exit chat?',
    endChatButton: 'Confirm',
    typing_bar: 'Say something...',
    liveAgentInitFail:
        "I'm sorry, live agent is not available now. Please try again later.",
    liveAgentTyping: 'Live agent is typing',
    liveAgentConnecting: 'Connecting',
    liveAgentEnded: 'Live agent {Live Agent Name} has left this chat',
    liveAgentTransferred: 'Live agent transferred.',
    liveAgentConnected: 'Connected to live agent.',
    liveAgentJoined: 'Live agent {Live Agent Name} joined this chat.',
    loadingQueueGreetingMessage: 'Hi',
    loadingQueueMessage:
        "You are currently queueing in position {Queue}. Kindly wait for our agent. We'll get to you very soon",
    loadingQueueOfflineForm: 'Cancel',
    prechatPlaceholder: 'Username',
    prechatGreetingMessage: 'Welcome, please enter your username to proceed.',
    prechatSubmitButton: 'Start',
    leaveMessage: 'Please leave your message.',
    welcome: 'hi',
    chatrating: "",
    chatratingFields: ""
};

export const storeSystemMessageToCache = createAsyncThunk(
    'systemMessagesState/storeSystemMessageToCache',
    async (args: argProps, thunkAPI) => {
        if (initialState.hasOwnProperty(args.name)) {
            const state = await setState(args.name, args.value);
            return state;
        } else {
            console.log('Not Found.');
            return false;
        }
    }
);

export const systemMessagesSlice = createSlice({
    name: 'systemMessagesState',
    initialState,
    reducers: {
        editSystemMessage: (state: any, action) => {
            console.log("editSystemMessage", action.payload.name, action.payload.value, action.payload.value.hasOwnProperty('fields'))
            state[action.payload.value.hasOwnProperty('fields')? action.payload.name + "Fields" : action.payload.name] = action.payload.value;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(
            storeSystemMessageToCache.fulfilled,
            (state: any, action: any) => {
                state[action.payload.name] = action.payload.value;
            }
        );
    },
});

// Action creators are generated for each case reducer function
export const { editSystemMessage } = systemMessagesSlice.actions;

export default systemMessagesSlice.reducer;

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import en from './locales/en.json';
import cn from './locales/cn.json';
import vn from './locales/vn.json';
import th from './locales/th.json';

i18n.use(LanguageDetector)
    .use(Backend)
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        react: {
            useSuspense: false, //   <---- this will do the magic
        },
        resources: {
            en: {
                // translation is the default namespace
                translation: en,
            },
            zh: {
                translation: cn,
            },
            vn: {
                translation: vn,
            },
            th: {
                translation: th,
            },
        },
        fallbackLng: 'en',
        debug: true,
        nonExplicitSupportedLngs: true,
        supportedLngs: ['en', 'zh', 'vn', 'th'], // available languages for browser dector to pick from
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
    });
export default i18n;

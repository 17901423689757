import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { editStatus } from '../../redux/state/status';
import classNames from 'classnames';

interface HyperlinkProps {
    url?: string;
    text: string;
}

const HyperlinkText = (props: HyperlinkProps) => {
    let dispatch = useDispatch();
    const botButtonColor = useSelector(
        (state: { settings: { botButtonColor: string } }) =>
            state.settings.botButtonColor
    );

    const intentFontSize = useSelector(
        (state: { settings: { intentFontSize: number } }) =>
            state.settings.intentFontSize
    );


    const fontSizesRef = ['xs', 'sm', 'base', 'lg', 'xl'];
    const smallFontSizeRef = ['2xs', 'xs', 'sm', 'base', 'lg'];
    const fontSizes = [
        fontSizesRef[0 + intentFontSize],
        fontSizesRef[1 + intentFontSize],
        fontSizesRef[2 + intentFontSize],
    ];
    const smallFontSizes = [
        smallFontSizeRef[0 + intentFontSize],
        smallFontSizeRef[1 + intentFontSize],
        smallFontSizeRef[2 + intentFontSize],
    ];

    const openLoginModal = () => {
        dispatch(
            editStatus({
                name: 'isLoginModal',
                value: true,
            })
        );
    };

    const openLoginIframeModal = () => {
        dispatch(
            editStatus({
                name: 'isLoginIframeModal',
                value: true,
            })
        );
        dispatch(
            editStatus({
                name: 'loginIframeUrl',
                value: props.url,
            })
        );
    };

    const openNewTab = () => {
        window.open(props.url)
    };

    const urlString = props.url;

    if (urlString?.includes("mobileApiLogin")) {
        return (
            <a
                onClick={openLoginModal}
                className={classNames(`text-white font-medium rounded-lg text-${fontSizes[0]} small:text-${fontSizes[0]} medium:text-${fontSizes[1]} large:text-${fontSizes[2]} px-2 py-1.5 m-1 text-left inline-flex cursor-pointer`, {
                  "bg-sky-500 hover:bg-sky-700": !botButtonColor
                })}
                style={{backgroundColor: botButtonColor?.slice(0,7)}}
            >
                {props.text}
            </a>
        );
    }
    else if (urlString?.includes("Login")) {
        return (
            <a
                onClick={openLoginIframeModal}
                className={classNames(`text-white font-medium rounded-lg text-${fontSizes[0]} small:text-${fontSizes[0]} medium:text-${fontSizes[1]} large:text-${fontSizes[2]} px-2 py-1.5 m-1 text-left inline-flex cursor-pointer `, {
                  "bg-sky-500 hover:bg-sky-700": !botButtonColor
                })}
                style={{backgroundColor: botButtonColor?.slice(0,7)}}
            >
                {props.text}
            </a>
        );
    }
    else {
        return (
            <a
                onClick={openNewTab}
                className={classNames(`text-white font-medium rounded-lg text-${fontSizes[0]} small:text-${fontSizes[0]} medium:text-${fontSizes[1]} large:text-${fontSizes[2]} px-2 py-1.5 m-1 text-left inline-flex cursor-pointer break-all`, {
                  "bg-sky-500 hover:bg-sky-700": !botButtonColor
                })}
                style={{backgroundColor: botButtonColor?.slice(0,7)}}
            >
                {props.text}
            </a>
        );
    }
};

HyperlinkText.propTypes = {};

export default HyperlinkText;

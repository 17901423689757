import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '../Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

interface Props {
    message: string;
    color: 'yellow' | 'red' | 'blue' | 'grey' | 'green';
    fn: any;
}


const MessageDialog = (props: Props) => {
    const botFontSize = useSelector(
        (state: { settings: { botFontSize: number } }) =>
            state.settings.botFontSize
    );
    const fontSizesRef = ['xs', 'sm', 'md', 'lg', 'xl'];
    const smallFontSizeRef = ['2xs', 'xs', 'sm', 'md', 'lg'];
    const fontSizes = [
        fontSizesRef[0 + botFontSize],
        fontSizesRef[1 + botFontSize],
        fontSizesRef[2 + botFontSize],
    ];
    const smallFontSizes = [
        smallFontSizeRef[0 + botFontSize],
        smallFontSizeRef[1 + botFontSize],
        smallFontSizeRef[2 + botFontSize],
    ];
    return (
        <div
            id='messageDialog'
            className={classNames('w-full rounded-xl flex items-center', {
                'bg-yellow-400/95': props.color == 'yellow',
                'bg-red-400/95': props.color == 'red',
                'bg-blue-400/95': props.color == 'blue',
                'bg-slate-400/95': props.color == 'grey',
                'bg-lime-400/95 ': props.color == 'green',
            })}
        >
            <p className={`flex-none w-80pc p-4 text-white text-${fontSizes[0]} small:text-${fontSizes[0]} medium:text-${fontSizes[1]} large:text-${fontSizes[2]}`}>{props.message}</p>
            <div className="grow"></div>
            <FontAwesomeIcon
                onClick={props.fn}
                icon={faCircleXmark}
                className="text-2xl p-2 text-white flex-none"
            />
        </div>
    );
};

MessageDialog.propTypes = {};

export default MessageDialog;


export const isImgLink = (url:string) => {
    let isImgUrl = url.match(/^http[^\?]*.(jpg|jpeg|gif|png|tiff|bmp)(\?(.*))?$/gmi) !== null;

    console.log("IS IMG URL", isImgUrl);

    const img = new Image();
    img.src = url;
    console.log("HEIGHT AND WIDTH", img.height, img.width);

    return isImgUrl;


}
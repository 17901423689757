import {
    createSlice,
    createAsyncThunk,
    PayloadAction,
    current,
} from '@reduxjs/toolkit';
import { setState, getState } from '../../utils/localStorage';
import { ChatInterface } from '../../utils/interfaces';
import { format } from 'date-fns';

const initialState: ChatInterface[] = [

];

export const chatSlice = createSlice({
    name: 'chatState',
    initialState,
    reducers: {
        addChat: (
            state: ChatInterface[],
            action: PayloadAction<ChatInterface>
        ) => {
            // console.log('addChat', action.payload);
            state.push(action.payload);
        },
        editChat: (
            state: ChatInterface[],
            action: PayloadAction<ChatInterface[]>
        ) => {
            state = action.payload;
            // console.log('edit', state, action.payload);
        },
    },
});

// Action creators are generated for each case reducer function
export const { addChat, editChat } = chatSlice.actions;

export default chatSlice.reducer;

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

interface Iprops {
    url?: string
    opacity?: number
    format?: number
}
const Background = (props: Iprops) => {
    return (
        <div
            style={{
                backgroundImage: props.url?`url(${props.url})`:'',
                // backgroundImage:"url('../assets/seamless-pattern-g48ecb0ed5_640.png')",
                backgroundSize: (props.format == 0) ? 'repeat' : (props.format == 1) ? 'cover' : (props.format == 2) ? 'contain' : 'contain',
                // backgroundSize: 'cover',
                opacity: props.opacity
            }}
            className={classNames("fixed top-0 w-full h-full -z-10", {
                "chat-background-g": !props.url
            })}
            // className="fixed top-0 w-full h-full -z-10 chat-background-g"
        ></div>
    );
};

Background.propTypes = {};

export default Background;

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSocket } from '../../hook/useSocket';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../Button';
import { useTranslation } from 'react-i18next';
import { useCacheService } from '../../hook/useCacheService';
import { removeDialog } from '../../redux/state/dialog';
import { addChat } from '../../redux/state/chat';
import format from 'date-fns/format';
import { ChatInterface } from '../../utils/interfaces';
import { editSession, editSessions } from '../../redux/state/session';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faFileInvoice } from '@fortawesome/free-solid-svg-icons';

interface Props {
    task: string;
    message: string;
    buttonMessage: string;
    isLoading?: boolean;
    data?: any;
}

const CaseDialog = (props: Props) => {
    const socket = useSocket();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const cacheService = useCacheService();
    const { setCacheByField } = useCacheService();

    const chatId = useSelector(
        (state: { session: { chatId: string } }) => state.session.chatId
    );

    const sessionId = useSelector(
        (state: { session: { sessionId: string } }) => state.session.sessionId
    );

    const username = useSelector(
        (state: { session: { username: string } }) => state.session.username
    );

    const caseNumber = useSelector(
        (state: { session: { caseNumber: String } }) => state.session.caseNumber
    );

    const caseCategory = useSelector(
        (state: { session: { caseCategory: String } }) => state.session.caseCategory
    );

    const laAffinityToken = useSelector(
        (state: { session: { laAffinityToken: string } }) =>
            state.session.laAffinityToken
    );

    const laSessionKey = useSelector(
        (state: { session: { laSessionKey: string } }) =>
            state.session.laSessionKey
    );

    const respondedToAgent = useSelector(
        (state: { session: { respondedToAgent: boolean } }) =>
            state.session.respondedToAgent
    );

    const chatCountReason = useSelector(
        (state: { session: { chatCountReason: string } }) =>
            state.session.chatCountReason
    );

    const handleCancelCaseDetails = () => {
        dispatch(removeDialog('caseDetails'));
    };

    const handleSendCaseDetails = (event: any) => {
        let caseDetails =
            `${t('caseNumber')}: ${caseNumber}` +
            '\n' +
            `${t('caseCategory')}: ${caseCategory}`;

        const content = {
            owner: 'user',
            name: username,
            type: 'text',
            message: {
                content: caseDetails,
            },
            displayTime: format(new Date(), 'hh:mmaa'),
            createdBy: new Date().getTime(),
        } as ChatInterface;
        cacheService.pushCache(sessionId + '_chats', JSON.stringify(content));
        dispatch(addChat(content));

        let data = {
            affinityToken: laAffinityToken,
            key: laSessionKey,
            message: caseDetails,
            type: 'message',
            username: username,
            chatId: chatId,
        };

        if (!respondedToAgent && chatCountReason != 'respondedToAgent') {
            dispatch(
                editSessions({
                    respondedToAgent: true,
                    chatCountReason: 'respondedToAgent',
                })
            );
        }

        socket.sendChasitorMessageEvent(data);

        dispatch(
            editSession({
                name: 'sentCaseDetails',
                value: true,
            })
        );

        setCacheByField(sessionId, 'sentCaseDetails', true);

        dispatch(removeDialog('caseDetails'));
    };

    const botFontSize = 1

    const fontSizesRef = ['xs', 'sm', 'md', 'lg', 'xl'];
    const smallFontSizeRef = ['2xs', 'xs', 'sm', 'md', 'lg'];
    const fontSizes = [
        fontSizesRef[0 + botFontSize],
        fontSizesRef[1 + botFontSize],
        fontSizesRef[2 + botFontSize],
    ];
    const smallFontSizes = [
        smallFontSizeRef[0 + botFontSize],
        smallFontSizeRef[1 + botFontSize],
        smallFontSizeRef[2 + botFontSize],
    ]

    return (
        <React.Fragment>
            <div
                className={classNames(
                    'w-full rounded-xl flex bg-[#53A0E3] flex-col'
                )}
            >
                <div
                    className={classNames(
                        'w-full rounded-xl flex bg-[#53A0E3] pt-2 pl-2 pr-2 pb-2' 
                    )}
                >

                    <FontAwesomeIcon
                        icon={faXmark}
                        className="absolute right-2 top-2 text-white"
                        onClick={handleCancelCaseDetails}
                    />
                    

                    <table
                        className={`flex-col w-800pc list-none text-white text-${fontSizes[0]} small:text-${fontSizes[0]} medium:text-${fontSizes[1]} large:text-${fontSizes[2]}`}
                    >
                        <tr className="text-left">
                            <th>{`${t('caseNumber')}`}</th>
                            <th>{`: ${caseNumber}`}</th>
                        </tr>
                        <tr className="text-left">
                            <th>{`${t('caseCategory')}`}</th>
                            <th>{`: ${caseCategory}`}</th>
                        </tr>
                    </table>
                </div>
                <div
                    className={classNames(
                        'w-full rounded-xl flex bg-[#53A0E3] pt-1 pb-1'
                    )}
                >
                    <Button
                        fns={[{ type: 'js', func: handleSendCaseDetails }]}
                        style={{ backgroundColor: 'bg-blue-500/95' }}
                        className={classNames(
                            `max-w-60pc small:max-w-60pc medium:max-w-40pc rounded-md m-auto py-1 px-2 pl-10 pr-10
                        text-blue-500 bg-white text-${smallFontSizes[0]} small:text-${smallFontSizes[0]} 
                            medium:text-${smallFontSizes[1]} large:text-${smallFontSizes[2]} shadow-sm`
                        )}
                    >
                        {t('betSlipSendBetSlip')}
                    </Button>
                </div>
            </div>
        </React.Fragment>
    );
};

CaseDialog.propTypes = {};

export default CaseDialog;

import { useDispatch } from "react-redux";
import classNames from "classnames";
import { editStatus } from "../../redux/state/status";
import { useTranslation } from "react-i18next";

const SuccessLoginModal = () => {
  let dispatch = useDispatch();
  const {t} = useTranslation();

  const closeSuccessLoginModal = () => {
    dispatch(
      editStatus({
        name: "isSuccessLoginModal",
        value: false,
      })
    );
  };

  return (
    <div
      className={classNames(
        "fixed w-screen h-screen bg-zinc-800/90 flex justify-center items-center z-20"
      )}
    >
      <div className="w-90pc xs:w-64 small:w-96 medium:w-128 bg-white shadow-lg rounded-xl overflow-hidden">
        <div className="mt-6 content text-center text-sm small:text-base medium:text-lg">
          <h3 className="text-xl text-black">{t('loginSuccess')}</h3>
        </div>
        <div>
          <button
          onClick={closeSuccessLoginModal}
            type="button"
            className="flex justify-center items-center w-80pc px-4 py-2 bg-sky-500 rounded-lg mx-auto mb-6 mt-4 text-white font-bold shadow-lg"
          >
              {t('back')}
          </button>
        </div>
      </div>
    </div>
  );
};

SuccessLoginModal.propTypes = {};

export default SuccessLoginModal;

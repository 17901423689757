import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { editSession, editSessions } from "../../redux/state/session";

const IntentSuggestionModal = () => {
    let dispatch = useDispatch();

    const isIntentSuggestionModal = useSelector(
        (state: { status: { isIntentSuggestionModal: Boolean } }) =>
            state.status.isIntentSuggestionModal
    );
    const intentSuggestion = useSelector(
        (state: { session: { intentSuggestion: any } }) => state.session.intentSuggestion
    );

    const botColor = useSelector(
        (state: { settings: { botColor: string } }) => state.settings.botColor
    );

    const handleClickedIntent = (intent:any) => {
        dispatch(
            editSessions({
                intentChoice: intent.intentName
            })
          );
    };
    if (isIntentSuggestionModal) {
        const intentNames = Object.entries(intentSuggestion).map((item) => {
            const intent:any = item[1];
            return (
                    <button className='h-10 py-2 bg-white w-full text-sm hover:bg-gray-100 text-left px-4'
                    style={{
                        backgroundColor: botColor ? botColor.slice(0, 7) : '',
                    }}
                    onClick={() => handleClickedIntent(intent)}
                    >
                        {intent.intentName}
                    </button>
            );
        });
        return(
            <div className='absolute max-h-40 w-full bottom-0 z-20 overflow-auto divide-y'>
                   {intentNames}
            </div>
        );
        // const a_list = ['apple', 'orange', 'mango'];
        // console.log(a_list);
        // const each_fruit = a_list.map((fruit:any) => {
        //     return <div className='py-2.5'>
        //         {fruit}
        //     </div>
        // });
        // return(
        //     <div className='absolute w-full'>
        //         <button>
        //         {each_fruit}
        //         </button>
        //     </div>
        // );
    } else {
        return(
        <div>
        </div>);
    }
    
};

IntentSuggestionModal.propTypes = {};

export default IntentSuggestionModal;
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setState, getState } from '../../utils/localStorage';

interface argProps {
    name: string;
    value: any;
}

const initialState = {
    isCached: false,
    isChatAlive: true, 
    isSessionAlive: true,
    isLoading: true,
    isForm: true,
    isIframe: false,
    iframeUrl: "",
    isImageModal: false,
    imageUrl: "",  
    isPrechat: true,
    isChatRatingModal: false,
    isChatRatingSubmitted: false,
    queueStatus: null,
    queueNumber: 0,
    isLoginModal: false,
    isLoadingModal: false,
    isSuccessLoginModal: false,
    isFailLoginModal: false,
    isFormModal: false,
    notificationSounds: 0,
    loginIframeUrl: "",
    isLoginIframeModal: false,
    intentId: "",
    isIntentSuggestionModal: false
};

export const storeStatusToCache = createAsyncThunk(
    'statusState/storeStatusToCache',
    async (args: argProps, thunkAPI) => {
        if (initialState.hasOwnProperty(args.name)) {
            const state = await setState(args.name, args.value);
            return state;
        } else {
            console.log('Not Found.');
            return false;
        }
    }
);

export const statusSlice = createSlice({
    name: 'statusState',
    initialState,
    reducers: {
        editStatus: (state: any, action) => {
            console.log("editStatus", action.payload.name, action.payload.value);
            state[action.payload.name] = action.payload.value;
        },
        //increment
        incStatus: (state: any, action) => {
            state[action.payload.name] += 1;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(
            storeStatusToCache.fulfilled,
            (state: any, action: any) => {
                state[action.payload.name] = action.payload.value;
            }
        );
    },
});

// Action creators are generated for each case reducer function
export const { editStatus, incStatus } = statusSlice.actions;

export default statusSlice.reducer;

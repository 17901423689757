import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSocket } from '../../hook/useSocket';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../Button';
import { useTranslation } from 'react-i18next';
import { useCacheService } from '../../hook/useCacheService';
import { removeDialog } from '../../redux/state/dialog';
import { addChat } from '../../redux/state/chat';
import format from 'date-fns/format';
import { ChatInterface } from '../../utils/interfaces';
import { editSession, editSessions } from '../../redux/state/session';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faFileInvoice } from '@fortawesome/free-solid-svg-icons';

interface Props {
    task: string;
    message: string;
    buttonMessage: string;
    isLoading?: boolean;
    data?: any;
}

const BetSlipDialog = (props: Props) => {
    const socket = useSocket();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const cacheService = useCacheService();
    const { setCacheByField } = useCacheService();

    const chatId = useSelector(
        (state: { session: { chatId: string } }) => state.session.chatId
    );

    const sessionId = useSelector(
        (state: { session: { sessionId: string } }) => state.session.sessionId
    );

    const username = useSelector(
        (state: { session: { username: string } }) => state.session.username
    );

    const betslipId = useSelector(
        (state: { session: { betslipId: string } }) => state.session.betslipId
    );

    const matchStartDate = useSelector(
        (state: { session: { matchStartDate: string } }) =>
            state.session.matchStartDate
    );

    const vendor = useSelector(
        (state: { session: { vendor: string } }) => state.session.vendor
    );

    const status = useSelector(
        (state: { session: { status: string } }) => state.session.status
    );

    const betSlipVals = useSelector(
        (state: { 
            session: { 
                betSlipVals: any
            };   
        }) => state.session.betSlipVals
    );

    const laAffinityToken = useSelector(
        (state: { session: { laAffinityToken: string } }) =>
            state.session.laAffinityToken
    );

    const laSessionKey = useSelector(
        (state: { session: { laSessionKey: string } }) =>
            state.session.laSessionKey
    );

    const respondedToAgent = useSelector(
        (state: { session: { respondedToAgent: boolean } }) =>
            state.session.respondedToAgent
    );

    const chatCountReason = useSelector(
        (state: { session: { chatCountReason: string } }) =>
            state.session.chatCountReason
    );

    const handleCancelBetSlip = () => {
        dispatch(removeDialog('betSlip'));
    };

    const handleSendBetSlip = (event: any) => {
        let betSlipDetails =
            `${t('betSlipVendor')}: ${betSlipVals[0]}` +
            '\n' +
            `${t('betSlipStatus')}: ${decodeURIComponent(betSlipVals[1])}` +
            '\n' +
            `${t('betSlipBetID')}: ${betSlipVals[2]}` +
            '\n' +
            `${t('betSlipMatchDateTime')}: ${decodeURIComponent(
                betSlipVals[3]
            )}`;

        const content = {
            owner: 'user',
            name: username,
            type: 'text',
            message: {
                content: betSlipDetails,
            },
            displayTime: format(new Date(), 'hh:mmaa'),
            createdBy: new Date().getTime(),
        } as ChatInterface;
        cacheService.pushCache(sessionId + '_chats', JSON.stringify(content));
        dispatch(addChat(content));

        let data = {
            affinityToken: laAffinityToken,
            key: laSessionKey,
            message: betSlipDetails,
            type: 'message',
            username: username,
            chatId: chatId,
        };

        if (!respondedToAgent && chatCountReason != 'respondedToAgent') {
            dispatch(
                editSessions({
                    respondedToAgent: true,
                    chatCountReason: 'respondedToAgent',
                })
            );
        }

        socket.sendChasitorMessageEvent(data);

        dispatch(
            editSession({
                name: 'sentBetSlip',
                value: betSlipVals[2],
            })
        );

        setCacheByField(sessionId, 'sentBetSlip', betSlipVals[2]);

        dispatch(removeDialog('betSlip'));
    };

    const botFontSize = 1

    const fontSizesRef = ['xs', 'sm', 'md', 'lg', 'xl'];
    const smallFontSizeRef = ['2xs', 'xs', 'sm', 'md', 'lg'];
    const fontSizes = [
        fontSizesRef[0 + botFontSize],
        fontSizesRef[1 + botFontSize],
        fontSizesRef[2 + botFontSize],
    ];
    const smallFontSizes = [
        smallFontSizeRef[0 + botFontSize],
        smallFontSizeRef[1 + botFontSize],
        smallFontSizeRef[2 + botFontSize],
    ];

    return (
        <React.Fragment>
            <div
                className={classNames(
                    'w-full rounded-xl flex bg-[#53A0E3] flex-col'
                )}
            >
                <div
                    className={classNames(
                        'w-full rounded-xl flex bg-[#53A0E3] pt-2 pl-4 pr-2 pb-1' 
                    )}
                >
                    {/* <p className={`flex-none w-50pc text-white small:w-50pc medium:w-75pc p-4 text-${fontSizes[0]} small:text-${fontSizes[0]} medium:text-${fontSizes[1]} large:text-${fontSizes[2]}`}>
                {`Bet Slip ID: ${props.message}`}
                
            </p> */}

                    <FontAwesomeIcon
                        icon={faXmark}
                        className="absolute right-2 top-2 text-white"
                        onClick={handleCancelBetSlip}
                    />
                    

                    {/* <ul
                        className={`flex-none w-60pc list-none text-white small:w-50pc medium:w-75pc p-4 pl-1 pr-0 text-${fontSizes[0]} small:text-${fontSizes[0]} medium:text-${fontSizes[1]} large:text-${fontSizes[2]}`}
                    >
                        <li>{`${t('betSlipVendor')}: ${vendor}`}</li>
                        <li>{`${t('betSlipStatus')}: ${decodeURIComponent(
                            status
                        )}`}</li>
                        <li>{`${t('betSlipBetID')}: ${betslipId}`}</li>
                        <li>{`${t(
                            'betSlipMatchDateTime'
                        )}: ${decodeURIComponent(matchStartDate)}`}</li>
                    </ul> */}
                    <table
                        className={`flex-none w-100pc list-none text-white small:w-70pc medium:w-90pc text-${smallFontSizes[0]} small:text-${smallFontSizes[0]} medium:text-${smallFontSizes[1]} large:text-${smallFontSizes[2]}`}
                    >
                        <tr className="text-left">
                            <th>{`${t('betSlipVendor')}`}</th>
                            <th>{`: ${betSlipVals[0]}`}</th>
                        </tr>
                        <tr className="text-left">
                            <th>{`${t('betSlipStatus')}`}</th>
                            <th>{`: ${decodeURIComponent(betSlipVals[1])}`}</th>
                        </tr>
                        <tr className="text-left">
                            <th>{`${t('betSlipBetID')}`}</th>
                            <th>{`: ${betSlipVals[2]}`}</th>
                        </tr>
                        <tr className="text-left">
                            <th>{`${t('betSlipMatchDateTime')}`}</th>
                            <th>{`: ${decodeURIComponent(betSlipVals[3])}`}</th>
                        </tr>
                    </table>
                </div>
                <div
                    className={classNames(
                        'w-full rounded-xl flex bg-[#53A0E3] p-3 pt-1'
                    )}
                >
                    <Button
                        fns={[{ type: 'js', func: handleSendBetSlip }]}
                        style={{ backgroundColor: 'bg-blue-500/95' }}
                        className={classNames(
                            `max-w-60pc small:max-w-60pc medium:max-w-40pc rounded-md m-auto py-1 px-2 pl-10 pr-10
                        text-blue-500 bg-white text-${smallFontSizes[0]} small:text-${smallFontSizes[0]} 
                            medium:text-${smallFontSizes[1]} large:text-${smallFontSizes[2]} shadow-sm`
                        )}
                    >
                        {t('betSlipSendBetSlip')}
                    </Button>
                </div>
            </div>
        </React.Fragment>
    );
};

BetSlipDialog.propTypes = {};

export default BetSlipDialog;

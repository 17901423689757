import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { setState, getState } from '../../utils/localStorage';
import { ChatInterface, DialogInterface } from '../../utils/interfaces';

interface DialogState {
    dialogList: string[];
    dialogs: DialogInterface[];
    loadingList: string[];
}

const initialState = {
    dialogList: [],
    dialogs: [],
    loadingList: []
};

export const dialogSlice = createSlice({
    name: 'dialogState',
    initialState,
    reducers: {
        addDialog: (
            state: DialogState,
            action: PayloadAction<DialogInterface>
        ) => {
            console.log(
                'addDialog',
                action.payload,
                state.dialogList,
                state.dialogs,
                !state.dialogList.includes(action.payload.task),
                ['timeout', 'disconnected', 'ended', 'forceClose', 'leaved'].includes(
                    action.payload.task
                ),
                ['timeout', 'disconnected', 'ended', 'forceClose', 'leaved'].some((arr) =>
                    state.dialogList.includes(arr)
                )
            );
            if (!state.dialogList.includes(action.payload.task)) {
                if (
                    ['timeout', 'disconnected', 'ended', 'forceClose', 'leaved'].includes(
                        action.payload.task
                    ) &&
                    ['timeout', 'disconnected', 'ended', 'forceClose', 'leaved'].some(
                        (arr) => state.dialogList.includes(arr)
                    )
                ) {
                    return;
                } else {
                    if (['timeout', 'disconnected', 'ended', 'forceClose', 'leaved'].includes(
                        action.payload.task
                    )) {
                        state.dialogList = []
                        state.dialogs = []
                    }
                    state.dialogList.push(action.payload.task);
                    state.loadingList.push(action.payload.task);
                    state.dialogs.push(action.payload);
                    console.log(
                        'addDialog here',
                        action.payload,
                        state.dialogList,
                        state.dialogs
                    );
                }
            }
        },
        removeDialog: (state: DialogState, action: PayloadAction<string>) => {
            const newDialogs = state.dialogs.filter((d) => {
                return d.task != action.payload;
            });
            state.dialogs = newDialogs;

            const newDialogList = state.dialogList.filter((d) => {
                return d != action.payload;
            });
            state.dialogList = newDialogList;
        },
        editDialog: (
            state: DialogState,
            action: PayloadAction<DialogInterface>
        ) => {
            state.dialogs = [action.payload];
            state.dialogList = [action.payload.task];
            console.log('edit', state, action.payload);
        },
    },
});

// Action creators are generated for each case reducer function
export const { addDialog, editDialog, removeDialog } = dialogSlice.actions;

export default dialogSlice.reducer;

import React, { createContext, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { editSetting } from '../redux/state/settings';
import { editSystemMessage } from '../redux/state/systemMessages';
import { error } from '../utils/console';
import { post } from '../utils/rest';
import i18n from '../i18n';
import { addFilter, addFilterString } from '../redux/state/chatFilter';

export const DataServiceContext = createContext({} as any);

const DataService: FC = ({ children }: any) => {
    const dispatch = useDispatch();

    const systemMessagesState = useSelector(
        (state: { systemMessages: any }) => state.systemMessages
    );

    const dataService = {
        loadData: async (path: string, botName: string) => {
            console.log('load data service');
            interface BotInfoInterface {
                bot_info: object;
                client_info: {
                    PreChat: object;
                    BotClientConfig?: object;
                    SystemMessages: {
                        intentName: string;
                        answer: string | { content: object };
                    }[];
                    ChatFilters: {
                        chatFilters: number;
                        chatFilterId: number;
                        filterName: string;
                        pattern: string;
                        priority: number;
                        replace: string;
                    }[];
                };
            }

            const isString = (value: unknown): value is string => {
                return !!value && typeof value === 'string';
            };

            const isBotInfo = (value: unknown): value is BotInfoInterface => {
                return !!value && typeof value === 'object';
            };

            return new Promise(async (resolve, reject) => {
                try {
                    const res = await post(`${path}/getInfo`, {
                        data: {
                            botName,
                        },
                    });

                    if (isString(res)) {
                        let resObject = JSON.parse(res);
                        console.log('successfully load data service');
                        console.log('cache response:', resObject);
                        if (isBotInfo(resObject)) {
                            const botSettings = resObject.bot_info;
                            const botClientConfig =
                                resObject.client_info.BotClientConfig;
                            const systemMessages =
                                resObject.client_info.SystemMessages;
                            const prechat = resObject.client_info.PreChat;
                            const chatFilters =
                                resObject.client_info.ChatFilters;
                            console.log(botSettings, systemMessages, prechat);
                            Object.entries(prechat).forEach(([key, value]) => {
                                dispatch(
                                    editSetting({
                                        name: key,
                                        value,
                                    })
                                );
                            });
                            Object.entries(botSettings).forEach(
                                ([key, value]) => {
                                    dispatch(
                                        editSetting({
                                            name: key,
                                            value,
                                        })
                                    );
                                    if (key == 'botLanguage') {
                                        if (value == 'Chinese') {
                                            i18n.changeLanguage('zh');
                                        } else if (value == 'Vietnamese') {
                                            i18n.changeLanguage('vn');
                                        } else if (value == 'Thai') {
                                            i18n.changeLanguage('th');
                                        } else if (value == 'English') {
                                            i18n.changeLanguage('en');
                                        } else {
                                            i18n.changeLanguage('en');
                                        }
                                    }
                                }
                            );
                            if (botClientConfig) {
                                console.log("botClientConfig", botClientConfig  )
                                Object.entries(botClientConfig).forEach(
                                    ([key, value]) => {
                                        dispatch(
                                            editSetting({
                                                name: key,
                                                value,
                                            })
                                        );
                                        if (key == 'botLanguage') {
                                            if (value == 'Chinese') {
                                                i18n.changeLanguage('zh');
                                            } else if (value == 'Vietnamese') {
                                                i18n.changeLanguage('vn');
                                            } else if (value == 'Thai') {
                                                i18n.changeLanguage('th');
                                            } else if (value == 'English') {
                                                i18n.changeLanguage('en');
                                            } else {
                                                i18n.changeLanguage('en');
                                            }
                                        }
                                    }
                                );
                            }
                            systemMessages.map((systemMessage, index) => {
                                // console.log(systemMessage.intentName, systemMessage.answer, typeof systemMessage.answer)
                                dispatch(
                                    editSystemMessage({
                                        name: systemMessage.intentName,
                                        value:
                                            typeof systemMessage.answer ==
                                            'object'
                                                ? systemMessage.answer.content
                                                : JSON.parse(
                                                      systemMessage.answer
                                                  ).content,
                                    })
                                );
                                if (index >= systemMessages.length - 1) {
                                    resolve(true);
                                }
                            });

                            chatFilters.map((filter, index) => {
                                dispatch(addFilter(filter));
                                dispatch(addFilterString(filter.pattern));
                            });
                        }
                    }
                } catch (e) {
                    error(
                        DataService.name,
                        'Exception when getting bot info from cache:',
                        e
                    );
                    reject(e);
                }
            });
        },
    };

    return (
        <>
            <DataServiceContext.Provider value={dataService}>
                {children}
            </DataServiceContext.Provider>
        </>
    );
};

export default DataService;

const botInfoHC = {
    botInfo: {
        accoundId: '4',
        botName: '小霸王',
        botId: '67',
        botLanguage: 'Chinese',
        botAvatar: 'https://res.bimariobot.com/avatars/67/Avatar.png',
    },
    botSettings: {
        accoundId: '4',
        botName: '小霸王',
        botId: '67',
        botLanguage: 'Chinese',
        botAvatar: 'https://res.bimariobot.com/avatars/67/Avatar.png',
    },
    displaySettings: {
        loaderType: '',
        loaderColor: '',
        botAvatar: '',
        botBanner: '',
        botBannerPotraitMode: '',
        botColor: '',
        externalLinkForOfflineForm: '',
    },
    prechat: {
        botId: 67,
        enablePreChat: true,
        enablePreChatBanner: true,
        greetingMessage:
            '您好，欢迎来到竞博，会员请输入用户名/访客输入联系方式',
        preChatBannerUrl:
            'https://res.bimariobot.com/avatars/67/PreChatBanner.png?v=175cbf5ca2db4190acc61015061cbfda',
        submitButtonText: '确认',
        usernamePlaceholder: '用户名 / 访客姓名',
    },
    systemMessages: [
        {
            answer: {
                type: 'text',
                content: {
                    message: '聊天重连',
                },
            },
            answerType: 1,
            intentId: 3172,
            intentName: 'reconnect_chat',
            intentType: 'system',
        },
        {
            answer: {
                type: 'text',
                content: {
                    message:
                        '您开启多个聊天窗口，故此目前聊天窗口将被断开，请于新聊天窗口继续即可。',
                },
            },
            answerType: 1,
            intentId: 5178,
            intentName: 'force_close',
            intentType: 'system',
        },
        {
            answer: {
                type: 'text',
                content: {
                    links: [],
                    message:
                        '<p>茫茫人海中与您相识是可遇不可求的缘分。喜欢客服的服务，在离别之前借用您发财贵手协助给予5星好评(*^▽^*)</p><p>让我们的相遇留下永恒的证明❥(ゝω・✿ฺ)，祝您游戏愉快 财源广进</p><p> </p>',
                },
            },
            answerType: 1,
            intentId: 4569,
            intentName: 'chatRatingMessage',
            intentType: 'system',
        },
        {
            answer: {
                type: 'form',
                content: {
                    text: '',
                    fields: [
                        {
                            name: '点评',
                            type: 'rating',
                            scales: {
                                '0': {
                                    name: 'Scale 5',
                                    text: '优秀',
                                    value: 5,
                                    isVisible: true,
                                },
                                '1': {
                                    name: 'Scale 4',
                                    text: '很好',
                                    value: 4,
                                    isVisible: true,
                                },
                                '2': {
                                    name: 'Scale 3',
                                    text: '好',
                                    value: 3,
                                    isVisible: true,
                                },
                                '3': {
                                    name: 'Scale 2',
                                    text: '还行',
                                    value: 2,
                                    isVisible: true,
                                },
                                '4': {
                                    name: 'Scale 1',
                                    text: '差',
                                    value: 1,
                                    isVisible: true,
                                },
                            },
                            compulsory: true,
                            isRequired: true,
                        },
                        {
                            name: '留言',
                            type: 'textArea',
                            compulsory: true,
                            isRequired: true,
                        },
                    ],
                    message: '',
                    ifNeedConfirm: true,
                },
            },
            answerType: 2,
            intentId: 4569,
            intentName: 'chatRatingForm',
            intentType: 'system',
        },
        {
            answer: {
                type: 'text',
                content: {
                    message: '客服正在输入..',
                },
            },
            answerType: 1,
            intentId: 3161,
            intentName: 'live_agent_typing',
            intentType: 'system',
        },
        {
            answer: {
                type: 'text',
                content: {
                    links: [],
                    message: '<p>请登入您的账号</p>',
                },
            },
            answerType: 1,
            intentId: 2237,
            intentName: 'requireauthentication',
            intentType: 'system',
        },
        {
            answer: {
                type: 'text',
                content: {
                    links: [],
                    message:
                        '<p>【竞程精彩，竞在竞博】USDT存款争霸战！年度以来最火热的存款方式，💯 安全有保障💯 交易速度快💯 高度匿名性</p>',
                },
            },
            answerType: 1,
            intentId: 2234,
            intentName: 'welcome',
            intentType: 'system',
        },
        {
            answer: {
                type: 'text',
                content: {
                    links: [],
                    message:
                        '<p>召唤师，不好意思。小霸王无法回应到您的问题。劳烦您点击【转人工客服】联系竞博在线客服为您服务。感恩</p>',
                },
            },
            answerType: 1,
            intentId: 2235,
            intentName: 'noanswer',
            intentType: 'system',
        },
        {
            answer: {
                type: 'text',
                content: {
                    links: [],
                    message: '转人工客服',
                },
            },
            answerType: 1,
            intentId: 2236,
            intentName: 'quickreplymessage',
            intentType: 'system',
        },
        {
            answer: {
                type: 'text',
                content: {
                    message: '距离您上次回复已有一段时间，本次聊天已结束。',
                },
            },
            answerType: 1,
            intentId: 3154,
            intentName: 'no_respond',
            intentType: 'system',
        },
        {
            answer: {
                type: 'text',
                content: {
                    message: '目前无法连接，请稍后再试.',
                },
            },
            answerType: 1,
            intentId: 3160,
            intentName: 'live_agent_init_fail',
            intentType: 'system',
        },
        {
            answer: {
                type: 'text',
                content: {
                    message: '已成功连接至人工客服。',
                },
            },
            answerType: 1,
            intentId: 3163,
            intentName: 'live_agent_connected',
            intentType: 'system',
        },
        {
            answer: {
                type: 'text',
                content: {
                    message: '客服已将聊天转给其他客服。',
                },
            },
            answerType: 1,
            intentId: 3165,
            intentName: 'live_agent_transferred',
            intentType: 'system',
        },
        {
            answer: {
                type: 'text',
                content: {
                    message: '重启聊天',
                },
            },
            answerType: 1,
            intentId: 3171,
            intentName: 'rejoin_chat',
            intentType: 'system',
        },
        {
            answer: {
                type: 'text',
                content: {
                    message: '访客已离开聊天。',
                },
            },
            answerType: 1,
            intentId: 3155,
            intentName: 'leave_chat',
            intentType: 'system',
        },
        {
            answer: {
                type: 'text',
                content: {
                    message: '聊天结束',
                },
            },
            answerType: 1,
            intentId: 3156,
            intentName: 'end_chat',
            intentType: 'system',
        },
        {
            answer: {
                type: 'text',
                content: {
                    message: '下载聊天记录',
                },
            },
            answerType: 1,
            intentId: 3157,
            intentName: 'download_chat_history',
            intentType: 'system',
        },
        {
            answer: {
                type: 'text',
                content: {
                    message: '请输入信息',
                },
            },
            answerType: 1,
            intentId: 3173,
            intentName: 'typing_bar',
            intentType: 'system',
        },
        {
            answer: {
                type: 'text',
                content: {
                    message: '正在连接竞博人工客服中……',
                },
            },
            answerType: 1,
            intentId: 3162,
            intentName: 'live_agent_conneting',
            intentType: 'system',
        },
        {
            answer: {
                type: 'text',
                content: {
                    message: '召唤师，劳烦您留言在此。客服会尽快与您取得联系。',
                },
            },
            answerType: 1,
            intentId: 3167,
            intentName: 'leave_message',
            intentType: 'system',
        },
        {
            answer: {
                type: 'text',
                content: {
                    message: '成功登入。',
                },
            },
            answerType: 1,
            intentId: 3168,
            intentName: 'success_login__message',
            intentType: 'system',
        },
        {
            answer: {
                type: 'text',
                content: {
                    message: '糟糕喏，网络出现异常。劳烦您重新连接。',
                },
            },
            answerType: 1,
            intentId: 3169,
            intentName: 'connection_problem_login',
            intentType: 'system',
        },
        {
            answer: {
                type: 'text',
                content: {
                    message: '取消登入。',
                },
            },
            answerType: 1,
            intentId: 3170,
            intentName: 'cancel_login',
            intentType: 'system',
        },
        {
            answer: {
                type: 'text',
                content: {
                    message:
                        '蟹蟹。竞博客服是24小时不打烊服务。我们随时欢迎您前来拜访我们。',
                },
            },
            answerType: 1,
            intentId: 3174,
            intentName: 'end_chat_header',
            intentType: 'system',
        },
        {
            answer: {
                type: 'text',
                content: {
                    message: '结束聊天？请点击【确认】',
                },
            },
            answerType: 1,
            intentId: 3175,
            intentName: 'end_chat_confirm_button',
            intentType: 'system',
        },
        {
            answer: {
                type: 'text',
                content: {
                    message:
                        '您好，这里是竞博专属通道，欢迎咨询客服。祝您"鼠"不尽的财富与钞票。',
                },
            },
            answerType: 1,
            intentId: 3177,
            intentName: 'queue_greeting_message',
            intentType: 'system',
        },
        {
            answer: {
                type: 'text',
                content: {
                    message:
                        '您排在队列第{queuePosition}号。估计等待时间约为{estimatedWaitTime}分钟。 谢谢您的耐心。',
                },
            },
            answerType: 1,
            intentId: 3178,
            intentName: 'queue_message',
            intentType: 'system',
        },
        {
            answer: {
                type: 'text',
                content: {
                    message:
                        '客服繁忙奋斗中，怠慢了您的时间实在不好意思。阁下可留下以下消息，英雄们战斗完毕会尽快给您回复。',
                },
            },
            answerType: 1,
            intentId: 3179,
            intentName: 'queue_offline_form_button',
            intentType: 'system',
        },
        {
            answer: {
                type: 'text',
                content: {
                    message: '客服{Live Agent Name}已离开聊天。',
                },
            },
            answerType: 1,
            intentId: 3166,
            intentName: 'live_agent_ended',
            intentType: 'system',
        },
        {
            answer: {
                type: 'text',
                content: {
                    message: '客服{Live Agent Name}已加入聊天。',
                },
            },
            answerType: 1,
            intentId: 3164,
            intentName: 'live_agent_joined',
            intentType: 'system',
        },
        {
            answer: {
                type: 'text',
                content: {
                    message: '请点击此处传送文件',
                },
            },
            answerType: 1,
            intentId: 3176,
            intentName: 'upload_file_button',
            intentType: 'system',
        },
        {
            answer: {
                type: 'text',
                content: {
                    message: '访客已取消聊天请求。',
                },
            },
            answerType: 1,
            intentId: 3158,
            intentName: 'cancel_form',
            intentType: 'system',
        },
        {
            answer: {
                type: 'text',
                content: {
                    message: '聊天中断，请刷新页面以继续聊天。',
                },
            },
            answerType: 1,
            intentId: 3159,
            intentName: 'network_problem',
            intentType: 'system',
        },
        {
            answer: {
                type: 'text',
                content: {
                    message:
                        '小霸王猜您想要了解以下内容，若都不合适麻烦亲详细描述一下哟~~',
                },
            },
            answerType: 1,
            intentId: 3625,
            intentName: 'intent_answer_option',
            intentType: 'system',
        },
        {
            answer: {
                type: 'text',
                content: {
                    message:
                        '小霸王正在等待您的回复，若未在时间内回复，则本次聊天将结束。',
                },
            },
            answerType: 1,
            intentId: 3748,
            intentName: 'timeout_warning_message',
            intentType: 'system',
        },
    ],
    chatFilters: {},
};

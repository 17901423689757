import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setState, getState } from '../../utils/localStorage';

interface TimerInterface {
    timeout: any;
}

const initialState: TimerInterface = {
    timeout: null,
};

export const timerSlice = createSlice({
    name: 'timerState',
    initialState,
    reducers: {
        startTimer: (state: any, action) => {
            if (state[action.payload.name] != null) {
                clearInterval(state[action.payload.name]);
                state[action.payload.name] = action.payload.value;
            }
            else {
                state[action.payload.name] = action.payload.value;
            }
        },
        stopTimer: (state: any, action) => {
            if (state[action.payload] != null) {
                clearInterval(state[action.payload]);
                state[action.payload] = null;
            }
        },
    },
});

// Action creators are generated for each case reducer function
export const { startTimer, stopTimer } = timerSlice.actions;

export default timerSlice.reducer;

export const filter = (reg: any, filters: any, text: string, matchedFilterMessage:string) => {
    return new Promise((resolve, reject) => {
        if (reg && text.match(reg)) {
            let matched_rules:any = [];
            filters.map((f: any, index: number) => {
                let pattern = JSON.parse(f.pattern);
                let matched = text.match(pattern)
                if (matched) {
                    // console.log("matched", matched)
                    matched_rules.push(f.filterName)
                    if (f.action == 1) {
                        text = text.replace(matched[0], '');
                    } else {
                        text = text.replace(matched[0], f.replace);
                    }
                }
                if (index == filters.length - 1) {
                    resolve([text, text + ` (${matchedFilterMessage}:` + matched_rules?.join(',') + ")"]);
                }
            });
        } else {
            resolve([text, text]);
        }
    });
};

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSocket } from '../../hook/useSocket';
import { useDispatch, useSelector } from 'react-redux';
import { ChatInterface } from '../../utils/interfaces';
import { addChat } from '../../redux/state/chat';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useCacheService } from '../../hook/useCacheService';
import { removeDialog } from '../../redux/state/dialog';

interface Props {
    task: string;
    message: string;
    buttonMessage: string;
    isLoading?: boolean;
    data?: any;
}

const UploadFileDialog = (props: Props) => {

    const socket = useSocket();
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const cacheService = useCacheService();

    const chatId = useSelector(
        (state: { session: { chatId: string } }) => state.session.chatId
    );

    const sessionId = useSelector(
        (state: { session: { sessionId: string } }) => state.session.sessionId
    );

    const username = useSelector(
        (state: { session: { username: string } }) => state.session.username
    );
 
    // Handles file upload event
    const handleUpload = (event:any) => {

        let file = event.target.files[0];
        let filename = file['name'];
        let filesize = file['size'];
        let filetype = file["type"].split("/");

        console.log("FILE", event.target.files[0]);
        console.log("UPLOADED FILE",filename,filesize, filetype);

        let fileSizeLimit = 5 * 1048576;

        if (filesize > fileSizeLimit) {

            const wrongFileSizeMessage = {
                owner: 'system',
                name: 'Mario',
                type: 'text',
                message: {
                    content:
                        t("fileUploadInvalid"),
                },
                displayTime: format(new Date(), 'hh:mmaa'),
                createdBy: new Date().getTime(),
            } as ChatInterface;
            dispatch(addChat(wrongFileSizeMessage));
            
        }

        else {

            let filename_message = filename;
        
            if (filename.length > 20) {
                filename_message = filename.slice(0,20) + "...";
            };

            const content = {
                owner: 'user',
                type: 'text',
                name: username,
                message: {
                    content: filename_message,
                },
                displayTime: format(
                    new Date(),
                    'hh:mmaa'
                ),
                createdBy: new Date().getTime(),
            } as ChatInterface;
            dispatch(addChat(content));
            cacheService.pushCache(
                sessionId + '_chats',
                JSON.stringify(content)
            );

            if (filetype[0] === "image") {

                const content = {
                    owner: 'user',
                    type: 'image',
                    name: username,
                    message: {
                        url: URL.createObjectURL(file),
                        content: "uploadImage",
                    },
                    displayTime: format(
                        new Date(),
                        'hh:mmaa'
                    ),
                    createdBy: new Date().getTime(),
                } as ChatInterface ;
                dispatch(addChat(content));

                cacheService.pushCache(
                    sessionId + '_chats',
                    JSON.stringify(content)
                );

            }

            let values = props.data;
            let reader = new FileReader();
            reader.readAsDataURL(file);


            reader.onload = function () {
                let data = {
                    "filename": filename,
                    "file": reader.result,
                    "url": values["uploadServletUrl"],
                    "orgId":  values["org_id"],
                    "chatKey":  values["id"],
                    "fileToken":  values["fileToken"],
                    "fileFlag" : "SALESFORCE",
                    "encoding": 'UTF-8',
                    "chatId": chatId,
                }

                dispatch(removeDialog('uploadFile'));
                console.log("DATE HERE", data);
                socket.sendUploadFileEvent(data);

            }

        }

    }

    const botFontSize = useSelector(
        (state: { settings: { botFontSize: number } }) =>
            state.settings.botFontSize
    );
    const fontSizesRef = ['xs', 'sm', 'md', 'lg', 'xl'];
    const smallFontSizeRef = ['2xs', 'xs', 'sm', 'md', 'lg'];
    const fontSizes = [
        fontSizesRef[0 + botFontSize],
        fontSizesRef[1 + botFontSize],
        fontSizesRef[2 + botFontSize],
    ];
    const smallFontSizes = [
        smallFontSizeRef[0 + botFontSize],
        smallFontSizeRef[1 + botFontSize],
        smallFontSizeRef[2 + botFontSize],
    ];


    return (
        <div
            className={classNames(`w-full rounded-xl flex bg-lime-200/95  text-${fontSizes[0]} small:text-${fontSizes[0]} medium:text-${fontSizes[1]} large:text-${fontSizes[2]}`
            )}
        >
            <p className="flex-none w-50pc small:w-50pc medium:w-75pc p-4">{props.message}</p>
            <div className="grow"></div>

      <label htmlFor="contained-button-file" className='max-w-40pc small:max-w-40pc medium:max-w-20pc rounded-xl flex p-2 m-2 text-white bg-lime-400'>

            <a>{props.buttonMessage}</a>

        </label>
            <input
                type="file"
                className='hidden'
                id="contained-button-file"
                onChange={handleUpload}
            />
        </div>
    );
};

UploadFileDialog.propTypes = {};

export default UploadFileDialog;

import { Route, Routes, Outlet, useParams } from 'react-router-dom';
import { stagingRoutes } from './config/stagingPath';
import { localRoutes } from './config/localPath';
import { store } from './redux/store';
import { Provider } from 'react-redux';
import { productionRoutes } from './config/productionPath';
import { productionRoutesDR1 } from './config/productionPathDR1';
import { productionRoutesDR2 } from './config/productionPathDR2';
import { productionRoutesDR3 } from './config/productionPathDR3';
import { routing } from './config/routing'

function App() {
    console.log(process.env.REACT_APP_DEPLOY_ENV, productionRoutes);

    let routeComponents;

    if (process.env.REACT_APP_DEPLOY_ENV == 'production' && routing['deployment'] == true) {

        console.log("Routing is true, routing to server", routing['routeToServer']);

        routeComponents =
        routing['routeToServer'] == 'dr1'
            ? productionRoutesDR1.map(({ path, component }, key) => (
                  <Route path={path} element={component} key={key} />
              ))
            : routing['routeToServer'] == 'dr2'
            ? productionRoutesDR2.map(({ path, component }, key) => (
                  <Route path={path} element={component} key={key} />
              )): routing['routeToServer'] == 'dr3'
              ? productionRoutesDR3.map(({path,component}, key) => (
                  <Route path={path} element={component} key={key} />
              ))
            :null;

    } else {

        routeComponents =
        process.env.REACT_APP_DEPLOY_ENV == 'production'
            ? productionRoutes.map(({ path, component }, key) => (
                  <Route path={path} element={component} key={key} />
              ))
            : (process.env.REACT_APP_DEPLOY_ENV == 'staging'
            ? stagingRoutes.map(({ path, component }, key) => (
                  <Route path={path} element={component} key={key} />
              ))
            : (process.env.REACT_APP_DEPLOY_ENV == 'local'
            ? localRoutes.map(({ path, component }, key) => (
                  <Route path={path} element={component} key={key} />
              ))
            : null));
        
    }

    console.log("routeComponents", routeComponents);

    return (
        <Provider store={store}>
            <Routes>
                <Route path="/">
                    {routeComponents}
                    <Route path="*" element={<NoMatch />} />
                </Route>
            </Routes>
        </Provider>
    );
}

function Home() {
    return <div>This is Home page</div>;
}

function NoMatch() {
    return (
        <div>
            <h2>Invalid Path</h2>
        </div>
    );
}

export default App;


    // const routeComponents =
    //     process.env.REACT_APP_DEPLOY_ENV == 'production'
    //         ? productionRoutes.map(({ path, component }, key) => (
    //               <Route path={path} element={component} key={key} />
    //           ))
    //         : (process.env.REACT_APP_DEPLOY_ENV == 'staging'
    //         ? stagingRoutes.map(({ path, component }, key) => (
    //               <Route path={path} element={component} key={key} />
    //           ))
    //         : (process.env.REACT_APP_DEPLOY_ENV == 'local'
    //         ? localRoutes.map(({ path, component }, key) => (
    //               <Route path={path} element={component} key={key} />
    //           ))
    //         : null));
import axios, { AxiosRequestHeaders } from 'axios';
import { info, error } from './console';

interface Params {
    headers?: AxiosRequestHeaders;
    data: object;
}

export const get = (url: string, restParams = null) => {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await axios.request({
                method: 'GET',
                url: url,
                timeout: 5000,
            });
            info(get.name, 'Response from request:', url, result);
            resolve(result.data);
        } catch (e) {
            error(post.name, 'POST Request Error:', e);
            reject(error);
        }
    });
};

export const post = (url: string, restParams: Params) => {
    return new Promise(async (resolve, reject) => {
        console.log("POST", url, restParams)
        try {
            const result = await axios.request({
                method: 'POST',
                url: url,
                headers: restParams.headers,
                data: restParams.data,
                timeout: 5000,
            });
            info(post.name, 'Response from request:', url, restParams, result);
            resolve(result.data);
        } catch (e) {
            error(post.name, 'POST Request Error:', e);
            reject(e);
        }
    });
};

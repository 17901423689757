export const setCookies = (
    key: string,
    value: any,
    exsec = 600
) => {
    const d = new Date();
    d.setTime(d.getTime() + exsec * 1000);
    var expires = 'expires=' + d.toUTCString();
    document.cookie =
        key + '=' + value + ';' + expires + ';path=/; SameSite=None; Secure';
};

export const removeCookie = (key: string) => {
    var expires = "expires=Thu, 01 Jan 1970 00:00:00 UTC";
    document.cookie = key + "=;" + expires + ";path=/; SameSite=None; Secure";
}

export const getCookies = (key: string) => {
    return new Promise((resolve, reject) => {
        const name = key + '=';
        try {
            const ca = document.cookie.split(';');
            if (ca) {
                for (var i = 0; i < ca.length; i++) {
                    let c = ca[i];
                    while (c.charAt(0) == ' ') {
                        c = c.substring(1);
                    }
                    if (c.indexOf(name) == 0) {
                        if (c.substring(name.length, c.length)) {
                            resolve(c.substring(name.length, c.length));
                        }
                    }
                }
            }
        } catch (e) {
            resolve('');
        }
        resolve('');
    });
};

export const refreshCookies = async (key: string, exsec = 600) => {
    const value = await getCookies(key);
    setCookies(key, value, exsec);
};

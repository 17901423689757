import { format } from 'date-fns';

export const info = (
    module: any,
    log: any,
    param1: any = '',
    param2: any = '',
    param3: any = ''
) => {
    let logText = `INFO: ${format(
        new Date(),
        'MM-dd HH:mm:ss'
    )}: ${module}: ${log}, ${param1}, ${param2}, ${param3}`;
    console.info(
        `INFO: ${format(new Date(), 'MM-dd HH:mm:ss')}: ${module}:`,
        log,
        param1,
        param2,
        param3
    );
};

export const error = (
    module: any,
    log: any,
    param1: any = '',
    param2: any = '',
    param3: any = ''
) => {
    console.error(
        `ERROR: ${format(new Date(), 'MM-dd HH:mm:ss')}: ${module}:`,
        log,
        param1,
        param2,
        param3
    );
};

export const important = (
    module: any,
    log: any,
    param1: any = '',
    param2: any = '',
    param3: any = ''
) => {
    console.log(
        `IMPORTANT: ${format(new Date(), 'MM-dd HH:mm:ss')}: ${module}:`,
        log,
        param1,
        param2,
        param3
    );
};

export const warning = (
    module: any,
    log: any,
    param1: any = '',
    param2: any = '',
    param3: any = ''
) => {
    console.warn(
        `WARNING: ${format(new Date(), 'MM-dd HH:mm:ss')}: ${module}:`,
        log,
        param1,
        param2,
        param3
    );
};

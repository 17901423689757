import { json } from "stream/consumers";

export const setState = (name:string, value:any) =>
    new Promise((resolve, reject) => {
        if (window.localStorage) {
            window.localStorage.setItem(name, value);
            resolve(value);
        }
    });

export const setStateJson = (name:string, value:any) =>
    new Promise((resolve, reject) => {
        if (window.localStorage) {
            window.localStorage.setItem(name, JSON.stringify(value));
            resolve(value);
        }
    });

export const getState = (name:string) => {
    if (window.localStorage) {
        return window.localStorage.getItem(name);
    }
};

export const getStateJson = (name:any) => {
    if (window.localStorage) {
        return JSON.parse(JSON.stringify(window.localStorage.getItem(name)));
    }
};

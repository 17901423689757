import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ChatInterface } from '../../utils/interfaces';

// const initialState: ChatInterface[] = [

// ];

const initialState = {
    filterStrings: '',
    filterRegex: null,
    filters: [],
};

export const chatFilter = createSlice({
    name: 'chatFilterState',
    initialState,
    reducers: {
        addFilterString: (state: any, action: any) => {
            state.filterStrings += '|' + JSON.parse(action.payload)[0];
        },
        addFilter: (state: any, action: any) => {
            state.filters.push(action.payload);
        },
    },
});

// Action creators are generated for each case reducer function
export const { addFilterString, addFilter } = chatFilter.actions;

export default chatFilter.reducer;

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { editStatus } from '../redux/state/status';

interface Props {
    height: number;
}

const ImageModal = (props:Props) => {
    // console.log("IMAGE MODAL HERE")

    const isImageModal = useSelector(
        (state: { status: { isImageModal: boolean } }) =>
            state.status.isImageModal
    );

    const imageUrl = useSelector(
        (state: { status: { imageUrl: string } }) => state.status.imageUrl
    );

    // console.log("VALUES SENT", isImageModal, imageUrl);

    const dispatch = useDispatch();
    const handleClick = () => {
        dispatch(editStatus({ name: 'isImageModal', value: false }));
    };

    return (
        <div
            style={{ height: props.height }}
            className={classNames(
                'absolute w-screen bg-zinc-800 z-20 flex items-center justify-center',
                { hidden: !isImageModal }
            )}
        >
            <div className="absolute inset-y-0 right-6 top-4 h-full w-1/10">
                <img
                    onClick={handleClick}
                    alt="icon"
                    src="https://cdn-icons-png.flaticon.com/512/458/458594.png"
                    className="h-10 cursor-pointer"
                />
            </div>

            <div
                className={classNames(
                    'px-4 py-2 rounded-lg inline-block max-w-80pc shadow-sm chat--disapear active flex flex-wrap'
                )}
            >
                <img src={imageUrl} />
            </div>
        </div>
    );
};

ImageModal.propTypes = {};

export default ImageModal;

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

interface Props {
    customizationColor: string;
    customizationType: string;
}

interface LooseObject {
    [key: string]: any;
}

const BounceLoader = (props: Props) => {
    const myType = 'bounce';
    let visibleCondition = {
        hidden: props.customizationType != myType,
    };

    let colorCondition: LooseObject = {};
    colorCondition[props.customizationColor] = true;

    return (
        <div className={classNames(visibleCondition)}>
            <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex justify-center">
                <span
                    className={classNames(
                        'circle animate-bounce',
                        colorCondition
                    )}
                ></span>
                <span
                    className={classNames(
                        'circle animate-bounce',
                        colorCondition
                    )}
                ></span>
                <span
                    className={classNames(
                        'circle animate-bounce',
                        colorCondition
                    )}
                ></span>
                <span
                    className={classNames(
                        'circle animate-bounce',
                        colorCondition
                    )}
                ></span>
                <span
                    className={classNames(
                        'circle animate-bounce',
                        colorCondition
                    )}
                ></span>
            </div>
        </div>
    );
};

BounceLoader.defaultProps = {
    customizationColor: 'bg-gray-500',
};

BounceLoader.propTypes = {
    customizationType: PropTypes.string,
    customizationColor: PropTypes.string,
    isCustomizationColor: PropTypes.bool,
};

export default BounceLoader;

import { configureStore } from '@reduxjs/toolkit';
import statusReducer from './state/status';
import chatReducer from './state/chat';
import settingReducer from './state/settings';
import systemMessagesReducer from './state/systemMessages'
import sessionReducer from './state/session'
import dialogReducer from './state/dialog'
import timerReducer from './state/timer'
import chatFilterReducer from './state/chatFilter'

export const store = configureStore({
    reducer: {
        status: statusReducer,
        chat: chatReducer,
        settings: settingReducer,
        systemMessages: systemMessagesReducer,
        session: sessionReducer,
        dialog: dialogReducer,
        timer: timerReducer,
        chatFilter: chatFilterReducer
    },
});

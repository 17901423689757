import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { editStatus } from '../redux/state/status';
import {
    FunctionInterface,
    JsFuntionInterface,
    ReduxFunctionInterface,
} from '../utils/interfaces';

interface ButtonProps {
    fns?: (ReduxFunctionInterface | JsFuntionInterface)[];
    children?: any;
    className?: any;
    disabled?: any;
    style?: any;
    dispatchAction?: any;
    dispatchPayload?: any;
}

const Button = (props: ButtonProps) => {
    const dispatch = useDispatch();

    const handleClick = () => {
        props.fns?.map((fn, index) => {
            if (fn.type == 'js') {
                fn.func();
            }
            if (fn.type == 'redux') {
                dispatch(fn.dispatchArg.action(fn.dispatchArg.value));
            }
        });
    };

    return (
        <button
            disabled={props.disabled}
            className={classNames(
                'flex justify-center items-center',
                props.className
            )}
            onClick={handleClick}
            style={props.style}
        >
            {props.children}
        </button>
    );
};

Button.propTypes = {};

export default Button;

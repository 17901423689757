import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { LooseObject } from '../utils/interfaces';

const ModalBackground = () => {
    const isLoading = useSelector((state: {status: {isLoading: boolean}}) => state.status.isLoading);
    const visibleCondition:LooseObject = {}
    visibleCondition['hidden'] = !isLoading;
    return (
        <div className={classNames("container fixed min-w-full min-h-full bg-white z-50", visibleCondition)}></div>
    );
};

ModalBackground.propTypes = {};

export default ModalBackground;

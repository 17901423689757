import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

interface Props {
    customizationColor: string;
    customizationType: string;
}

interface LooseObject {
    [key: string]: any;
}

const WaveLoader = (props: Props) => {
    const myType = 'wave';
    let conditionsWaveSpan: LooseObject = {};
    conditionsWaveSpan[props.customizationColor] = true;

    let conditionsWaveDiv: LooseObject = {};
    conditionsWaveDiv['hidden'] = props.customizationType != myType;

    return (
        <div
            className={classNames(
                'fixed w-48 h-32 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg',
                conditionsWaveDiv
            )}
        >
            <div className="wave-div justify-items-center">
                {Array.apply(null, Array(8)).map((item, index) => {
                    return (
                        <span
                            className={classNames(
                                'wave-span',
                                'animate-wave',
                                conditionsWaveSpan
                            )}
                            key={index}
                        ></span>
                    );
                })}
            </div>
        </div>
    );
};

WaveLoader.propTypes = {
    customizationType: PropTypes.string,
    customizationColor: PropTypes.string,
    isCustomizationColor: PropTypes.bool,
};

export default WaveLoader;

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { editStatus } from '../../redux/state/status';

interface Props {
    height: number;
}

const IframeModal = (props:Props) => {
    // console.log("IMAGE MODAL HERE")

    const isIframeModal = useSelector(
        (state: { status: { isIframeModal: boolean } }) =>
            state.status.isIframeModal
    );

    const iframeUrl = useSelector(
        (state: { status: { iframeUrl: string } }) => state.status.iframeUrl
    );
    const dispatch = useDispatch()

    const handleClick = () => {
        dispatch(editStatus({ name: 'isIframeModal', value: false }));
        dispatch(editStatus({ name: 'iframeUrl', value: "" }));
    };

    return (
        <div
            style={{ height: props.height }}
            className={classNames(
                'fixed w-screen bg-fixed flex justify-center z-20 bg-zinc-800',
                { hidden: !isIframeModal }
            )}
        >
            {/* <div className="absolute right-4 top-4">
                <img
                    onClick={handleClick}
                    alt="icon"
                    src="https://cdn-icons-png.flaticon.com/512/458/458594.png"
                    className="h-10 cursor-pointer"
                />
            </div> */}

            <div className= "bg-zinc-800 h-full relative">
                <img
                    onClick={handleClick}
                    alt="icon"
                    src="https://cdn-icons-png.flaticon.com/512/458/458594.png"
                    className="absolute right-2 top-4 cursor-pointer w-8"
                />

                <iframe className='w-screen h-full bg-slate-200 overflow-scroll' src={iframeUrl} />
            </div>
        </div>


    );
};

IframeModal.propTypes = {};

export default IframeModal;

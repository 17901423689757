export const getBrowser = () => {
    const userAgent = navigator.userAgent ?? "";
    let versionPattern;
    let browser = "";

    if (userAgent.includes("Edg")) {
        versionPattern = /Edg(?:e|A|iOS)?\/(\d+(\.\d+)+)/;
    } else if (userAgent.includes("Firefox")) {
        versionPattern = /Firefox\/(\d+(\.\d+)*)/;
    } else if (userAgent.includes("FxiOS")) {
        versionPattern = /FxiOS\/(\d+(\.\d+)*)/;
    } else if (userAgent.includes("OPR")) {
        versionPattern = /OPR\/(\d+(\.\d+)*)/;
    } else if (userAgent.includes("SamsungBrowser")) {
        versionPattern = /SamsungBrowser\/(\d+(\.\d+)*)/;
    } else if (userAgent.includes("Brave")) {
        if (userAgent.includes("Brave ")){
            browser = "Brave";
        } else {
            versionPattern = /Brave\/(\d+(\.\d+)*)/;
        }
    } else if (userAgent.includes("Chrome")) {
        versionPattern = /Chrome\/(\d+(\.\d+)*)/;
    } else if (userAgent.includes("CriOS")) {
        versionPattern = /CriOS\/(\d+(\.\d+)*)/;
    } else if (userAgent.includes("Safari")) {
        versionPattern = /Safari\/(\d+(\.\d+)*)/;
    }
    
    if (versionPattern) {
        const matchVersion = userAgent.match(versionPattern);

        if (matchVersion) {
            browser = matchVersion[0].replace('/', ' ');
        }
    }

    return browser;
};

export const getPlatform = () => {
    const userAgent = navigator.userAgent ?? "";
    const matchPlatform = userAgent.match(/\(([^)]+)\)/);
    let sfPlatform = "";

    if (matchPlatform) {
        const platformDetails = matchPlatform[1];

        if (platformDetails.includes("iPhone") || platformDetails.includes("iPod") || platformDetails.includes("iPad")) {
            const matchVersion = platformDetails.match(/(\d+(_\d+)*) like Mac OS X/);
            sfPlatform = matchVersion ? "iOS " + matchVersion[1] : "iOS";
        } else if (platformDetails.includes("Macintosh")) {
            const matchVersion = platformDetails.match(/Mac OS X (\d+(_\d+)*)\b/);
            sfPlatform = matchVersion ? "macOS " + matchVersion[1] : "macOS";
        } else if (platformDetails.includes("Windows")) {
            const matchVersion = platformDetails.match(/Windows NT (\d+(\.\d+)*)/);
            sfPlatform = matchVersion ? "Windows NT " + matchVersion[1] : "Windows";
        } else if (platformDetails.includes("Android")) {
            const matchVersion = platformDetails.match(/Android? (\d+(\.\d+)*)/);
            sfPlatform = matchVersion ? "Android " + matchVersion[1] : "Android";
        } else if (platformDetails.includes("Linux")) {
            const matchVersion = platformDetails.match(/Linux ([a-zA-Z0-9_\-]+)?(?: on ([a-zA-Z0-9_\-]+))?;?/);
            sfPlatform = matchVersion ? matchVersion[0].replace(/;/g, "") : "Linux";
        } else if (platformDetails.includes("OpenBSD")) {
            const matchVersion = platformDetails.match(/OpenBSD ([a-zA-Z0-9_\-]+)?(?: on ([a-zA-Z0-9_\-]+))?;?/);
            sfPlatform = matchVersion ? matchVersion[0].replace(/;/g, "") : "OpenBSD";
        }
    }

    return sfPlatform;
}

import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Beforeunload, useBeforeunload } from 'react-beforeunload';
import { useDispatch, useSelector } from 'react-redux';
import { editSession } from '../redux/state/session';
import { editStatus } from '../redux/state/status';
import NotificationSound from '../assets/sound/notification.mp3';
import {Howl, Howler} from 'howler';


const BackgroundProcess = (props: any) => {
    const audioPlayer: any = useRef(null);

    const notification = useSelector(
        (state: any) => state.status.notificationSounds
    );

    function playAudio() {
        audioPlayer.current.play();
    }

    const sound = new Howl({
        src: [NotificationSound]
      });

    useEffect(() => {
        if (notification > 0) {
            // playAudio();
            sound.play();
        }
    }, [notification]);

    return (
        <div>
            <audio ref={audioPlayer} src={NotificationSound} />
        </div>
    );
};

BackgroundProcess.propTypes = {};

export default BackgroundProcess;

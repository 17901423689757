import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import BounceLoader from '../component/BounceLoader';
// import WaveLoader from '../component/WaveLoader';
import WaveLoader from '../component/WaveLoader';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import Loaders from '../component/Loaders';
import { LooseObject } from '../utils/interfaces';
import { dataLoader } from '../utils/dataLoader';
import { useDataService } from '../hook/useDataService';

interface Props {
    customizationColor: string;
    customizationType: string;
    isCustomizationColor: boolean;
    isLoading: boolean;
}

const Loading = (props: Props) => {
    const { t, i18n } = useTranslation();

    let colorClass: LooseObject = {};
    colorClass[props.customizationColor] = props.isCustomizationColor;
    return (
        <div className="z-50">
            {props.isLoading &&
                new Loaders(
                    props.customizationColor,
                    props.customizationType
                ).getLoader()}
        </div>
    );
};

Loading.defaultProps = {
    isLoading: false,
    isCustomizationColor: false,
    customizationType: 'wave',
    customizationColor: 'bg-blue-500',
};

Loading.propTypes = {
    isLoading: PropTypes.bool,
    customizationType: PropTypes.string,
    customizationColor: PropTypes.string,
    isCustomizationColor: PropTypes.bool,
};

export default Loading;

import React from 'react';
import PropTypes from 'prop-types';
import Interweave from 'interweave';
import { useSelector } from 'react-redux';

interface SystemMessageProps {
    message: string;
}

const SystemMessage = (props: SystemMessageProps) => {
    const botFontSize = useSelector(
        (state: { settings: { botFontSize: number } }) =>
            state.settings.botFontSize
    );

    const fontSizesRef = ['xs', 'sm', 'md', 'lg', 'xl'];
    const smallFontSizeRef = ['2xs', 'xs', 'sm', 'md', 'lg'];
    const fontSizes = [
        fontSizesRef[0 + botFontSize],
        fontSizesRef[1 + botFontSize],
        fontSizesRef[2 + botFontSize],
    ];
    const smallFontSizes = [
        smallFontSizeRef[0 + botFontSize],
        smallFontSizeRef[1 + botFontSize],
        smallFontSizeRef[2 + botFontSize],
    ];
    return (
        <div className="relative w-full flex items-center justify-center z-10 mt-4">
            <hr className="absolute top-3 large:top-4 w-95pc h-0.5 bg-slate-200" />
            <span className={`max-w-50pc mx-auto text-${fontSizes[0]} small:text-${fontSizes[0]} medium:text-${fontSizes[1]} large:text-${fontSizes[2]} text-slate-500 text-center top-0 bg-slate-200 px-2 py-1 z-20 rounded-full`}>
                <Interweave content={props.message} />
            </span>
        </div>
    );
};

SystemMessage.propTypes = {};

export default SystemMessage;
